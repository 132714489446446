import * as mapboxgl from 'mapbox-gl';
import AdFormService from '../services/AdFormService';
import Logging from '../Logging';
import PageData from '../PageData';
import AdLocationData from '../types/AdLocationData';
import AdCoordinates from '../AdCoordinates';
import {Section} from "../services/Section";
import MapData from "../types/MapData";

export default class LocationMap {
    private readonly mapStaticImage: HTMLImageElement;

    private readonly viewMapButton: HTMLElement;

    private readonly accessToken: string;

    private readonly adLocationData: AdLocationData;

    private readonly streetZoomLevel: number;

    private readonly districtZoomLevel: number;

    private readonly localityZoomLevel: number;
    private readonly country: string;
    private mapData: MapData;
    private pageViewType: string;
    private pageViewId: string;
    private id: string;

    constructor(
        {mapData, country, pageViewType, pageViewId, id} : {mapData: MapData, country: string, pageViewType: string, pageViewId: string, id: string}) {
        this.mapStaticImage = document.getElementById('map-static-image') as HTMLImageElement;
        this.viewMapButton = document.getElementById('view-map-button') as HTMLElement;
        this.accessToken = 'pk.eyJ1IjoibG9ra3VtYXBib3giLCJhIjoiY2t5YjcybHlrMGNqMTJ2cGJxdTF0NXA4aiJ9.xj2Td6NHaabLEFvQNPfjFg';
        this.adLocationData = mapData.adLocationData;
        this.streetZoomLevel = 15;
        this.districtZoomLevel = 14;
        this.localityZoomLevel = 13;
        this.country = country;
        this.mapData = mapData;
        this.pageViewType = pageViewType;
        this.pageViewId = pageViewId;
        this.id = id

    }

    private setup = () => {
        if (window.IntersectionObserver) {
            const showPin = this.shouldShowPin(this.mapData.visibility, this.adLocationData.address);

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.lazyLoadMap(showPin);
                        observer.unobserve(entry.target);
                    }
                });
            }, {rootMargin: '0px 0px 100px 0px'});
            const locationMap = document.getElementById('location-map-wrapper');
            if (locationMap) observer.observe(locationMap);
        }
    };

    static init(
        {mapData, country, pageViewType, pageViewId, id} : {mapData: MapData, country: string, pageViewType: string, pageViewId: string, id: string} ) {
        new LocationMap({mapData, country, pageViewType, pageViewId, id}).setup();
    }

    private lazyLoadMap(showPin: boolean) {
        this.getCoordinatesFromLocation(this.adLocationData)
            .then((coordinatesFromLocation) => {
                if (this.isCoordinateValid(coordinatesFromLocation.longitude, coordinatesFromLocation.latitude)) {
                    this.removeNoLocationAvailableElement();
                    this.showLocationMapElement();
                    const zoom = this.getProperZoomLevel();
                    this.setStaticMapApiUrl(coordinatesFromLocation, zoom, showPin);
                    this.setupStaticMapEventListener(coordinatesFromLocation, zoom, showPin);
                } else {
                    this.removeStaticMapComponents();
                    this.showNoLocationAvailableElement();
                }
            });
    }


    private async getCoordinatesFromLocation(adLocationData: AdLocationData) {
        if (this.isCoordinateValid(adLocationData.coordinates.longitude, adLocationData.coordinates.latitude)) return adLocationData.coordinates;

        if (!this.isEmpty(this.adLocationData.address)) {
            const coordinatesFromLocation = await this.getCoordinatesFromMapBoxWithLocation(this.buildGeocodingApiQueryAddress());
            if (this.isCoordinateValid(coordinatesFromLocation.longitude, coordinatesFromLocation.latitude)) return coordinatesFromLocation;
        }

        if (!this.isEmpty(this.adLocationData.province)) {
            const coordinatesFromLocation = await this.getCoordinatesFromMapBoxWithLocation(this.buildGeocodingApiQuery());
            if (this.isCoordinateValid(coordinatesFromLocation.longitude, coordinatesFromLocation.latitude)) return coordinatesFromLocation;
        }

        return new AdCoordinates('0.0', '0.0');
    }

    private async getCoordinatesFromMapBoxWithLocation(location: string): Promise<AdCoordinates> {
        return this.getMapBoxLatitudeLongitudeFrom(location)
            .then((longLat) => {
                if (longLat) {
                    const long = longLat[0].toString();
                    const lat = longLat[1].toString();
                    return new Promise((resolve) => {
                        resolve(new AdCoordinates(lat, long));
                    });
                }
                return new Promise((resolve) => {
                    resolve(new AdCoordinates('0.0', '0.0'));
                });
            });
    }

    private async getMapBoxLatitudeLongitudeFrom(query: string) {
        const mapBoxGeocodingApiUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
        const res = await fetch(`${mapBoxGeocodingApiUrl + query}.json?access_token=${this.accessToken}&country=${this.country}`);
        const geocodingResponse = await res.json();
        if (geocodingResponse.features.length > 0) {
            return geocodingResponse.features[0].center;
        }
    }

    private getProperZoomLevel(): number {
        if (this.isCoordinateValid(this.adLocationData.coordinates.longitude, this.adLocationData.coordinates.latitude)) return this.streetZoomLevel;
        if (!this.isEmpty(this.adLocationData.address) || !this.isEmpty(this.adLocationData.district) || !this.isEmpty(this.adLocationData.postcode)) return this.districtZoomLevel;
        return this.localityZoomLevel;
    }

    private setupStaticMapEventListener = (coordinates: AdCoordinates, zoom: number, showPin: boolean) => {
        if (this.viewMapButton != null) {
            this.viewMapButton.addEventListener('click', () => {
                this.setupMap(coordinates, zoom, showPin);
            });

        }

        this.mapStaticImage.addEventListener('dragstart', () => {
            this.setupMap(coordinates, zoom, showPin);
        });

    };

    public setupMap = (coordinates: AdCoordinates, zoom: number, showPin: boolean) => {
        const map = this.buildMap(coordinates.longitude, coordinates.latitude, zoom, showPin);
        if (showPin) {
            this.buildPin(map, coordinates.longitude, coordinates.latitude);
        }
        this.removeStaticMapComponents();
    };

    private buildMap = (longitude: string, latitude: string, zoom: number, showPin: boolean) => {
        let lastZoom = zoom;
        const map = new mapboxgl.Map({
            accessToken: this.accessToken,
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [parseFloat(longitude), parseFloat(latitude)],
            zoom,
        });

        map.on('zoomend', () => {
            const currentZoom = map.getZoom();
            lastZoom = currentZoom;
        });

        return map;
    };

    private buildPin(map: mapboxgl.Map, longitude: string, latitude: string) {
        const el = document.createElement('div');
        el.className = 'marker';
        new mapboxgl.Marker(el)
            .setLngLat([parseFloat(longitude), parseFloat(latitude)])
            .addTo(map);
    }

    private isEmpty(value: string): boolean {
        return typeof (value) === undefined || value == null || value == '';
    }

    private shouldShowPin(visibility: string, address: string): boolean {
        return (visibility == 'accurate' || visibility == '') && (this.isCoordinateValid(this.adLocationData.coordinates.longitude, this.adLocationData.coordinates.latitude) || !this.isEmpty(address));
    }

    private isCoordinateValid(longitude: string, latitude: string) {
        return !this.isEmpty(latitude) && !this.isEmpty(longitude) && parseFloat(longitude) != 0 && parseFloat(latitude) != 0
            && parseFloat(longitude) >= -180.0 && parseFloat(longitude) <= 180.0 && parseFloat(latitude) >= -90.0 && parseFloat(latitude) <= 90.0;
    }

    private buildGeocodingApiQuery(): string {
        return `${this.adLocationData.postcode},${this.adLocationData.district},${this.adLocationData.locality},${this.adLocationData.province}`;
    }

    private buildGeocodingApiQueryAddress(): string {
        return this.adLocationData.address;
    }

    private showLocationMapElement() {
        const map = document.getElementById('location-map');
        if (map != null) {
            map.classList.remove('hidden');
        }
    }

    private showNoLocationAvailableElement() {
        const noLocationWrapper = document.getElementById('no-location-wrapper');
        if (noLocationWrapper != null) {
            this.showLocationMapElement();
            noLocationWrapper.classList.remove('hidden');
        }
    }

    private removeNoLocationAvailableElement() {
        const noLocationWrapper = document.getElementById('no-location-wrapper');
        if (noLocationWrapper != null) {
            this.showLocationMapElement();
            noLocationWrapper.remove();
        }
    }

    private removeStaticMapComponents() {
        this.mapStaticImage.remove();

        if (this.viewMapButton != null) {
            this.viewMapButton.remove()
        }
    }

    private trackMapImpressionAfterLoadingMap(showPin: boolean) {
        if (this.pageViewType !== 'PROJECT_PAGE_DIRECTORY') {
            new AdFormService().impression(this.pageViewId, Section.SECTION_ADPAGE_MAP, [this.id])
        } else {
            new AdFormService().impressionProjectLocationMap(this.pageViewId, this.id).catch((error) => Logging.error(error));
        }
    }

    public setStaticMapApiUrl = (coordinates: AdCoordinates, zoom: number, showPin: boolean) => {
        this.trackMapImpressionAfterLoadingMap(showPin);
    };
}
